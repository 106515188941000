import { RefObject, useMemo, useState } from "react";
import Icon from "../../../../components/Icon";
import ProfilePic from "../../../../components/profileDisplay/ProfilePic";
import { compareDate } from "../../../../react-helpers/array";
import { formatDate } from "../../../../react-helpers/date";
import { BookingProfile } from "../../../../services/bookingProfile/bookingProfileModel";
import { BookingRequest } from "../../../../services/bookingRequest/bookingRequestModel";
import RatingDisplay from "./RatingDisplay";

enum RatingSort {
  Recent = "RECENT",
  Best = "BEST",
  Worst = "WORST",
}

const RatingsList = ({
  bookingProfile,
  ratings,
  ratingsRef,
  ratingsAmount,
  commentsAmount,
}: {
  bookingProfile: BookingProfile;
  ratings: BookingRequest[];
  ratingsRef?: RefObject<HTMLDivElement>;
  ratingsAmount: number | null;
  commentsAmount: number | null;
}) => {
  const [ratingsSort, setRatingsSort] = useState<RatingSort>(RatingSort.Recent);
  const sortedRatings = useMemo(() => {
    return [...ratings].sort((a, b) => {
      const aIsSender = bookingProfile.id === a.senderId;
      const aClosureDate = aIsSender
        ? a.recipientClosureDate!
        : a.senderClosureDate!;
      const aRating = aIsSender ? a.recipientRating : a.senderRating;

      const bIsSender = bookingProfile.id === b.senderId;
      const bClosureDate = bIsSender
        ? b.recipientClosureDate!
        : b.senderClosureDate!;
      const bRating = bIsSender ? b.recipientRating : b.senderRating;

      switch (ratingsSort) {
        case RatingSort.Recent:
          return compareDate(bClosureDate, aClosureDate);
        case RatingSort.Best:
          return bRating! - aRating!;
        case RatingSort.Worst:
          return aRating! - bRating!;
      }
    });
  }, [bookingProfile.id, ratings, ratingsSort]);

  return (
    ratings.length > 0 && (
      <div className="section cblocks" ref={ratingsRef}>
        <div className="cblocks lrow --distribute--h --center--v">
          <div>
            <h2 className="section_title" id="ratings">
              Notes ({ratingsAmount}) et avis ({commentsAmount})
            </h2>
            <div className="cblock">
              <RatingDisplay profile={bookingProfile} />
            </div>
          </div>
          <div>
            <select
              className="select"
              value={ratingsSort}
              onChange={(ev) => {
                setRatingsSort(ev.target.value as RatingSort);
              }}
            >
              <option value={RatingSort.Recent}>les plus récents</option>
              <option value={RatingSort.Best}>les mieux notés</option>
              <option value={RatingSort.Worst}>les moins bien notés</option>
            </select>
          </div>
        </div>
        <div className="cblocks">
          {sortedRatings.map((bookingRequest) => {
            const isExternal = !!bookingRequest.external;
            const isSender = bookingRequest.senderId === bookingProfile.id;
            const otherProfile = isSender
              ? bookingRequest.recipient
              : bookingRequest.sender;
            const otherRating = isSender
              ? bookingRequest.recipientRating
              : bookingRequest.senderRating;
            const otherComment = isSender
              ? bookingRequest.recipientComment
              : bookingRequest.senderComment;
            const otherClosureDate = isSender
              ? bookingRequest.recipientClosureDate
              : bookingRequest.senderClosureDate;

            return (
              <div key={bookingRequest.id} className="cblocks profile-display">
                <div className="lrow">
                  <ProfilePic
                    src={otherProfile?.user.profilePicture}
                    className="--s"
                  />
                  <div className="--stretched cblocks">
                    <div className="cblocks --xs">
                      <div className="profile_name">
                        {isExternal
                          ? bookingRequest.extDisplayName
                          : otherProfile?.user.displayName}
                      </div>
                      {otherClosureDate && (
                        <div className="body--30">
                          {formatDate(otherClosureDate)}
                        </div>
                      )}
                      <div className="rating-display">
                        <Icon name="star" />
                        <span>
                          <strong>{otherRating}</strong>
                          /5
                        </span>
                      </div>
                    </div>
                    {otherComment && (
                      <div className="info --msg">{otherComment}</div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

export default RatingsList;
