import { array, object, string } from "yup";
import SubmitButton from "../../../../../../../components/forms/SubmitButton";
import SxField from "../../../../../../../forms/fields/SxField";
import SxForm from "../../../../../../../forms/SxForm";
import { transformEmptyToUndefined } from "../../../../../../../react-helpers/yup";
import kwalaFunctions from "../../../../../../../services/externalDataIntegration/kwalaData/kwalaFunctions.json";
import kwalaIndustries from "../../../../../../../services/externalDataIntegration/kwalaData/kwalaIndustries.json";
import kwalaCompanySizes from "../../../../../../../services/externalDataIntegration/kwalaData/kwalaCompanySizes.json";
import kwalaSeniorities from "../../../../../../../services/externalDataIntegration/kwalaData/kwalaSeniorities.json";
import {
  ExternalContact,
  KwalaInitiateSearchData,
} from "../../../../../../../services/externalDataIntegration/kwalaData/kwalaDataModel";
import { ExternalDataIntegrationService } from "../../../../../../../services/externalDataIntegration/externalDataIntegrationService";
import { useOutletContext } from "react-router-dom";
import { ContactsOutletContext } from "../ContactsImportDialog";
import { useState } from "react";
import Icon from "../../../../../../../components/Icon";
import ExternalContactsToEnrichList from "../targetImportStep/ExternalContactsToEnrichList";
import { cx } from "../../../../../../../react-helpers/css";

const TargetImportStep = () => {
  const externalData = ExternalDataIntegrationService();
  const { campaign } = useOutletContext<ContactsOutletContext>();
  const [fetchingContacts, setFetchingContacts] = useState(false);
  const [externalContacts, setExternalContacts] = useState<
    ExternalContact[] | null
  >(null);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(true);

  return (
    <div className="lblocks">
      <div className="cblocks">
        <h2 className="paragraph_title --txt--center">
          1 - Définissez vos critères de ciblage
        </h2>
        <div className="card">
          <div className="card_body --bg">
            <SxForm
              initialValues={{
                keywords: "",
                industries: [],
                company_size: [],
                functions: [],
                seniorities: [],
              }}
              onSubmit={(values) => {
                const mappedRequestData: KwalaInitiateSearchData = {
                  ...values,
                  keywords: values.keywords.split(","),
                  seniorities: values.seniorities?.map((s) => Number(s)),
                };

                setExternalContacts([]);
                setFetchingContacts(true);

                return externalData
                  .getTargettedContacts(campaign.id, mappedRequestData)
                  .then((res) => {
                    setFetchingContacts(false);
                    setExternalContacts(res);
                    res.length > 0 && setShowAdvancedSearch(false);
                  });
              }}
              validationSchema={object({
                keywords: string().label("Mots clés").required(),
                industries: array(string())
                  .label("Secteurs d'activité")
                  .transform(transformEmptyToUndefined),
                company_size: array(string())
                  .label("Effectifs")
                  .transform(transformEmptyToUndefined),

                functions: array(string())
                  .label("Fonctions")
                  .transform(transformEmptyToUndefined),
                seniorities: array(string())
                  .label("Niveaux hierarchiques")
                  .transform(transformEmptyToUndefined),
              })}
            >
              <div className="flex-grid --bottom">
                <SxField name="keywords" placeholder={"ex: ceo"} />
                {(!showAdvancedSearch ||
                  (!!externalContacts && externalContacts?.length > 0)) && (
                  <button
                    type="button"
                    className={cx([
                      "lblock btn--3 btn--collapse --shrink",
                      showAdvancedSearch && "--open",
                    ])}
                    onClick={() => setShowAdvancedSearch((prev) => !prev)}
                  >
                    Plus de critères
                  </button>
                )}
              </div>

              {!!showAdvancedSearch && (
                <div className="lblock">
                  <div className="grid">
                    <SxField
                      as="autocomplete"
                      name="industries"
                      placeholder={"- Sélectionnez des secteurs d'activité -"}
                      options={kwalaIndustries}
                      isMulti
                    />
                    <SxField
                      as="autocomplete"
                      name="company_size"
                      placeholder={"- Sélectionnez des effectifs -"}
                      options={kwalaCompanySizes}
                      isMulti
                    />
                    <SxField
                      as="autocomplete"
                      name="functions"
                      placeholder={"- Sélectionnez des fonctions -"}
                      options={kwalaFunctions}
                      isMulti
                    />
                    <SxField
                      as="autocomplete"
                      name="seniorities"
                      placeholder={"- Sélectionnez des niveaux hierarchiques -"}
                      options={kwalaSeniorities}
                      isMulti
                    />
                  </div>
                </div>
              )}
              <div className="lblock--l --txt-center">
                <SubmitButton type="submit" className="btn">
                  Rechercher
                </SubmitButton>
              </div>
            </SxForm>
          </div>
        </div>
      </div>
      <div>
        {fetchingContacts && (
          <div className="callout">
            <Icon name="reset" className="--spin" />
            <div>
              <strong>RECHERCHE EN COURS...</strong>
              <br />
              Nous recherchons les meilleurs contacts correspondant à vos
              critères dans plusieurs bases de données, cela peut prendre
              jusqu'à 20 secondes. Merci de votre patience !
            </div>
          </div>
        )}
        {!!externalContacts && externalContacts?.length > 0 && (
          <ExternalContactsToEnrichList
            externalContacts={externalContacts}
            campaignId={campaign.id}
          />
        )}
        {!fetchingContacts &&
          !!externalContacts &&
          externalContacts?.length === 0 && (
            <div className="info">
              Mince ! Nous n'avons trouvé aucun contact correspondant à votre
              recherche...
              <br />
              Vous pouvez modifier vos critères et réessayer !
            </div>
          )}
      </div>
    </div>
  );
};

export default TargetImportStep;
