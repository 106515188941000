import { baseAPI } from "../api";
import { Campaign } from "../campaign/campaignModel";
import {
  ExternalContact,
  KwalaInitiateSearchData,
  KwalaResponse,
} from "./kwalaData/kwalaDataModel";

export function kwalaInitiateSearchRequest(
  campaignId: Campaign["id"],
  kwalaInitiateSearchData: KwalaInitiateSearchData,
): Promise<KwalaResponse> {
  return baseAPI
    .post(
      `/campaigns/${campaignId}/kwala/initiate-search?start_from=0&count=50`,
      kwalaInitiateSearchData,
    )
    .then(({ data }) => data);
}

export function kwalaEnrichContactsRequest(
  campaignId: Campaign["id"],
  contactsToEnrich: ExternalContact[],
): Promise<void> {
  return baseAPI.post(
    `/campaigns/${campaignId}/kwala/enrich`,
    contactsToEnrich,
  );
}
