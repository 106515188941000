import React, {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useSearchParams } from "react-router-dom";
import Icon from "./Icon";

const PaginatedList = <Item,>({
  items,
  render,
  totalItems: totalItemsWithoutExtra,
  className,
  pageSizes = [10, 20, 30],
  defaultPageSize = 20,
  scrollToAnchor,
  extraElement,
}: {
  items: Item[];
  render: (item: Item) => React.JSX.Element | null;
  totalItems: number;
  className?: string;
  pageSizes?: number[];
  defaultPageSize?: number;
  scrollToAnchor?: RefObject<HTMLElement>;
  extraElement?: ReactNode;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const totalItems = useMemo(() => {
    return totalItemsWithoutExtra + (extraElement ? 1 : 0);
  }, [totalItemsWithoutExtra, extraElement]);

  const currentPage = useMemo(() => {
    return searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  }, [searchParams]);

  const paginatedBy = useMemo(() => {
    return searchParams.get("limit")
      ? Number(searchParams.get("limit"))
      : defaultPageSize;
  }, [defaultPageSize, searchParams]);

  const lastPage = useMemo(() => {
    return Math.ceil(totalItems / paginatedBy);
  }, [totalItems, paginatedBy]);

  useEffect(() => {
    // If the current page is less than 1, set the page to 1
    if (currentPage < 1) {
      setSearchParams((prev) => {
        prev.set("page", "1");
        return prev;
      });
      // If the current page is greater than the last page, set the page to the last page
    } else if (currentPage > lastPage) {
      setSearchParams((prev) => {
        prev.set("page", `${lastPage}`);
        return prev;
      });
    }
  }, [setSearchParams, searchParams, totalItems, lastPage, currentPage]);

  // Function to check if the page is not the first or the last page when you want to shift to a specific page
  const isNotFirstOrLastPage = useCallback(
    (pageOffset: number) => {
      return pageOffset > 0
        ? currentPage + pageOffset <= lastPage
        : currentPage + pageOffset >= 1;
    },
    [currentPage, lastPage],
  );

  const isPageValid = useMemo(
    () => currentPage <= lastPage,
    [currentPage, lastPage],
  );

  const hasPreviousPage = useMemo(
    () => isNotFirstOrLastPage(-1),
    [isNotFirstOrLastPage],
  );
  const hasNextPage = useMemo(
    () => isPageValid && isNotFirstOrLastPage(1),
    [isNotFirstOrLastPage, isPageValid],
  );

  // Function to go to a specific page => set the query param "page"
  const shiftToPageOffset = useCallback(
    (pageOffset: number) => {
      scrollToAnchor?.current?.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
      setSearchParams((prev) => {
        prev.set("page", String(currentPage + pageOffset));
        return prev;
      });
    },
    [currentPage, setSearchParams, scrollToAnchor],
  );

  const goToNextPage = useCallback(
    () => shiftToPageOffset(1),
    [shiftToPageOffset],
  );
  const goToPreviousPage = useCallback(
    () => shiftToPageOffset(-1),
    [shiftToPageOffset],
  );

  return (
    <div className="paginated-list">
      {/* Displaying the list's elements */}
      <div className={className}>
        {items.map((item) => render(item))}
        {!hasNextPage && extraElement}
      </div>

      {/* list's pagination */}
      <div className="list_footer">
        <select
          className="select --inline --s"
          onChange={(evt) => {
            const limit = evt.target.value;
            setSearchParams((prev) => {
              if (!prev.get("page")) {
                if (Number(limit) < totalItems) prev.set("page", "1");
              }
              prev.set("limit", limit);
              return prev;
            });
          }}
          value={paginatedBy}
        >
          {pageSizes.map((limit) => (
            <option key={limit} value={limit}>
              {limit} par page
            </option>
          ))}
        </select>

        <div className="list_pagination">
          {lastPage > 1 && currentPage > 0 && (
            <>
              <button
                onClick={() => goToPreviousPage()}
                disabled={!hasPreviousPage}
              >
                <Icon name="arrow-back" />
              </button>

              <div>
                {currentPage > 2 && (
                  <button
                    onClick={() =>
                      setSearchParams((prev) => {
                        prev.set("page", "1");
                        return prev;
                      })
                    }
                  >
                    1
                  </button>
                )}
                {currentPage - 1 > 2 && "..."}
                {hasPreviousPage && (
                  <button
                    onClick={goToPreviousPage}
                    disabled={!hasPreviousPage}
                  >
                    {isPageValid ? currentPage - 1 : lastPage - 1}
                  </button>
                )}

                <button className="--active">
                  {isPageValid ? currentPage : lastPage}
                </button>

                {hasNextPage && (
                  <button onClick={goToNextPage} disabled={!hasNextPage}>
                    {currentPage + 1}
                  </button>
                )}
                {currentPage + 1 < lastPage - 1 && "..."}
                {currentPage < lastPage - 1 && (
                  <button
                    onClick={() =>
                      setSearchParams((prev) => {
                        prev.set("page", String(lastPage));
                        return prev;
                      })
                    }
                    disabled={!hasNextPage}
                  >
                    {lastPage}
                  </button>
                )}
              </div>

              <button onClick={() => goToNextPage()} disabled={!hasNextPage}>
                <Icon name="arrow-right" />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaginatedList;
