import Dialog from "../../../components/Dialog";
import { User } from "../../../services/user/userModel";
import NoProfileFoundCta from "./NoProfileFoundCta";
import { MappedConfigData } from "../../../services/data/dataModel";

const TargetingProfilesHelperDialog = ({
  loggedUser,
  onClose,
  filtersOptions,
}: {
  loggedUser?: User;
  onClose: () => void;
  filtersOptions: MappedConfigData;
}) => {
  return (
    <Dialog onClose={onClose} className="--m">
      <>
        <div className="popup_head">
          <h2 className="popup_title">
            Autres possibilités pour trouver des profils
          </h2>
        </div>
        <div className="popup_body">
          <NoProfileFoundCta
            filtersOptions={filtersOptions}
            user={loggedUser}
            afterSubmit={onClose}
            withTitle={false}
          />
        </div>
      </>
    </Dialog>
  );
};

export default TargetingProfilesHelperDialog;
