import { useState } from "react";

import Img1 from "../../../assets/img/landings/how-it-works-probono-1.png";
import Img2 from "../../../assets/img/landings/how-it-works-probono-2.png";
import Img3 from "../../../assets/img/landings/how-it-works-probono-3.jpeg";
import Img4 from "../../../assets/img/landings/how-it-works-probono-4.png";
import { cx } from "../../../react-helpers/css";

// TODO REFACTO : more dynamic behaviour (based on data object)
function HowItWorksProbonoSteps() {
  const [step, setStep] = useState(1);

  return (
    <>
      <div className="landing_steps">
        <button
          className={cx(["step_item", step === 1 && "--active"])}
          onClick={() => setStep(1)}
        >
          <span>1</span>
        </button>
        <button
          className={cx(["step_item", step === 2 && "--active"])}
          onClick={() => setStep(2)}
        >
          <span>2</span>
        </button>
        <button
          className={cx(["step_item", step === 3 && "--active"])}
          onClick={() => setStep(3)}
        >
          <span>3</span>
        </button>
        <button
          className={cx(["step_item", step === 4 && "--active"])}
          onClick={() => setStep(4)}
        >
          <span>4</span>
        </button>
      </div>
      <div className="container --wrap--s">
        {step === 1 && (
          <div className="l--content-img fade-in">
            <div className="cblocks --xs">
              <h3 className="paragraph_title">
                Recherchez l’interlocuteur de votre choix
                <br /> parmi les profils Tisio
              </h3>
            </div>
            <div className="card --200 rotate--m10">
              <img src={Img3} alt="" />
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="l--content-img fade-in">
            <div className="cblocks --xs">
              <h3 className="paragraph_title">Réservez sa prochaine dispo</h3>
            </div>
            <div className="card --200 rotate--10">
              <img src={Img1} alt="" />
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="l--content-img fade-in">
            <div className="cblocks --xs">
              <h3 className="paragraph_title">
                Effectuez une visio de 30 minutes avec lui/elle
              </h3>
            </div>
            <div className="card --200 rotate--m10">
              <img src={Img4} alt="" />
            </div>
          </div>
        )}

        {step === 4 && (
          <div className="l--content-img fade-in">
            <div className="cblocks --xs">
              <h3 className="paragraph_title">
                Faites un don à l’association qu’il soutient
              </h3>
            </div>
            <div className="card --200 rotate--10">
              <img src={Img2} alt="" />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default HowItWorksProbonoSteps;
