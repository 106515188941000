import Icon from "../../../../../../components/Icon";
import { Link } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { ContactsOutletContext } from "./ContactsImportDialog";

function ContactsImportTypeChoiceStep() {
  const { campaign } = useOutletContext<ContactsOutletContext>();
  return (
    <div className="container lcol --gap--l --center">
      <h2 className="page_title --txt-center">
        Comment souhaitez-vous
        <br />
        importer vos contacts ?
      </h2>
      <div className="lrow --stretch">
        <Link className="btn--card" to="csv">
          <Icon name="svg" />
          <span>Importer un fichier CSV</span>
        </Link>
        <Link className="btn--card" to="manual">
          <Icon name="keyboard" />
          <span>Saisir les contacts manuellement</span>
        </Link>
        {campaign.type === "MANAGED" && (
          <Link className="btn--card" to="targetted">
            <Icon name="target" />
            <span>Rechercher des contacts par critères de ciblage</span>
          </Link>
        )}
      </div>
    </div>
  );
}

export default ContactsImportTypeChoiceStep;
