import { useSearchParams } from "react-router-dom";

import LogoAdHumanis from "../../../assets/img/clients-logos/logo-adhumanis.png";
import LogoTeletech from "../../../assets/img/clients-logos/logo-teletech.png";
import LogoElos from "../../../assets/img/clients-logos/logo-elos.png";
import LogoWinbound from "../../../assets/img/clients-logos/logo-winbound.png";
import LogoKw from "../../../assets/img/clients-logos/logo-kw.png";
import LogoArgeste from "../../../assets/img/clients-logos/logo-argeste.png";
import LogoOlivesAndCo from "../../../assets/img/clients-logos/logo-olives-and-co.png";
import LogoKairos from "../../../assets/img/clients-logos/logo-kairos.png";
import GifProbono from "../../../assets/img/Creation-profil-Pro-Bono-2.gif";
import ImgBenefit1 from "../../../assets/img/landings/benefit-5.png";
import ImgBenefit2 from "../../../assets/img/landings/benefit-2.svg";
import ImgBenefit3 from "../../../assets/img/landings/benefit-3.svg";
import ImgBenefit4 from "../../../assets/img/landings/benefit-4.svg";
import HowItWorksProbonoSteps from "./HowItWorksProbonoSteps";
import ProfileSearch from "../../../components/ProfileSearch";

const ProbonoLandingPage = () => {
  const [searchParams] = useSearchParams();

  return (
    <div className="landing">
      <div className="landing_head --fit">
        <div className="container">
          <h1 className="head_title lblock--xl --force-first">
            Bienvenue sur <em>Tisio</em>
            <br />
            Plateforme de <em className="--txt-nowrap">
              visios rémunérées
            </em>{" "}
          </h1>
          <h2 className="section_title lblock">
            Des échanges BtoB plus intelligents, plus responsables, plus
            vertueux.
          </h2>
          <h3 className="head_tagline lblock">
            → Un décideur que vous avez sollicité vous a renvoyé vers Tisio
            <br />→ Vous souhaitez solliciter un décideur et vous voulez voir
            s’il est sur Tisio
          </h3>
          <ProfileSearch
            search={searchParams.get("search") ?? ""}
            title="Recherchez votre interlocuteur"
            targetUrl="/probono-profiles"
          />
        </div>
      </div>
      <div className="section --logos">
        <img src={LogoAdHumanis} alt="Avanci" />
        <img src={LogoTeletech} alt="Teletech" />
        <img src={LogoElos} alt="Elos" />
        <img src={LogoWinbound} alt="Winbound" />
        <img src={LogoKw} alt="Keller Williams" />
        <img src={LogoArgeste} alt="Argeste" />
        <img src={LogoOlivesAndCo} alt="Olives & co" />
        <img src={LogoKairos} alt="Kairos" />
      </div>

      <div className="section --light">
        <div className="container">
          <div className="l--img-content">
            <div>
              <img src={GifProbono} alt="" />
            </div>
            <div className="lblocks --l">
              <div className="cblocks">
                <h2 className="section_title">
                  Tisio révolutionne les échanges B2B en instaurant un modèle
                  gagnant-gagnant-gagnant :
                </h2>
                <p>
                  <strong>Les décideurs</strong> ne subissent plus les
                  sollicitations et peuvent choisir des échanges pertinents.
                </p>
                <p>
                  <strong>Les entreprises</strong> transforment leurs budgets
                  marketing en dons utiles et engagés.
                </p>
                <p>
                  <strong>Les associations</strong> bénéficient directement de
                  ces échanges sous forme de financement.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section --blue --bg-1 lblocks" id="rewarding">
        <div className="container lblocks --xl">
          <h2 className="section_title --txt--center">
            Le système de sollicitation
            <br />
            responsable
          </h2>

          <div className="benefits">
            <div className="benefit">
              <img src={ImgBenefit1} alt="Don à une association" />
              <div>
                <h3>
                  Chaque rendez-vous effectué via Tisio donne lieu à un don à
                  une association.
                </h3>
              </div>
            </div>
            <div className="benefit">
              <img src={ImgBenefit2} alt="Engagement" />
              <div>
                <h3>Garantit l’engagement et la motivation des solliciteurs</h3>
              </div>
            </div>
            <div className="benefit">
              <img src={ImgBenefit3} alt="Valorisation" />
              <div>
                <h3>
                  Valorise le temps des décideurs, qui s’engagent ainsi pour une
                  cause
                </h3>
              </div>
            </div>
            <div className="benefit">
              <img src={ImgBenefit4} alt="Respect de vos futurs clients" />
              <div>
                <h3>
                  Transforme les budgets marketing en actions RSE, leur donnant
                  une réelle utilité
                </h3>
              </div>
            </div>
          </div>
          <h3 className="section_title --txt--center">
            Un nouveau standard des relations BtoB :<br />
            plus intelligent, plus responsable et plus vertueux.
          </h3>
        </div>
      </div>

      <div className="section --blue --bg-2 lblocks" id="howitworks">
        <div className="container lblocks --xl">
          <h2 className="section_title --txt--center">Comment ça marche ?</h2>
          <HowItWorksProbonoSteps />
        </div>
      </div>
    </div>
  );
};

export default ProbonoLandingPage;
