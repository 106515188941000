import { createRef, useMemo, useState } from "react";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { BookingProfile } from "../../services/bookingProfile/bookingProfileModel";
import { MappedConfigData } from "../../services/data/dataModel";
import BookingProfileCard from "../../components/profiles/BookingProfileCard";
import PaginatedList from "../../components/PaginatedList";
import BookingProfileSearch from "./BookingProfileSearch";
import BackButton from "../../components/BackButton";
import { User } from "../../services/user/userModel";
import NoProfileFoundBlock from "./NoProfileFound/NoProfileFoundBlock";
import Icon from "../../components/Icon";
import TargetingProfilesHelperDialog from "./NoProfileFound/TargetingProfilesHelperDialog";
import { Bookmark } from "../../services/bookmark/bookmarkModel";
import { bookmarkService } from "../../services/bookmark/bookmarkService";
import useReload from "../../hooks/useReload";
import NoProfileFoundCta from "./NoProfileFound/NoProfileFoundCta";
import ProfilePic from "../../components/profileDisplay/ProfilePic";
import RatingDisplay from "../user/BookingProfile/bookingProfileManagementPage/RatingDisplay";

import bradPittDev from "../../assets/img/brad-pitt-dev.jpg";
import bradPittProd from "../../assets/img/brad-pitt-prod.jpg";

const { createBookmark, deleteBookmark } = bookmarkService();

const ProfilesListPage = () => {
  const {
    bookingProfiles,
    totalProfilesNb,
    configData,
    loggedUser,
    bookmarks,
  } = useLoaderData() as {
    bookingProfiles: BookingProfile[];
    bookmarks: Bookmark[];
    configData: MappedConfigData;
    totalProfilesNb: number;
    loggedUser?: User;
  };

  const reload = useReload();

  const [searchParams, setSearchParams] = useSearchParams();
  const scrollAnchor = createRef<HTMLDivElement>();

  const [
    showTargetingProfilesHelperDialog,
    setShowTargetingProfilesHelperDialog,
  ] = useState(false);

  const sort = useMemo(() => {
    return searchParams.get("sort") ?? "rating:desc";
  }, [searchParams]);

  const bookmarksMap = useMemo(
    () =>
      Object.fromEntries(
        bookmarks.map((bookmark) => [bookmark.bookingProfileId, bookmark]),
      ),
    [bookmarks],
  );

  return (
    <div className="container" id="top">
      <div ref={scrollAnchor} />
      <div className="page_head">
        <div>
          <BackButton fallbackUrl="/app/dashboard" />
          <h1 className="page_title">Liste des profils</h1>
        </div>
      </div>
      <div className="page-content">
        {bookingProfiles.length === 0 &&
        Array.of(searchParams.entries()).length === 0 ? (
          <div className="info">
            <div className="info_title">
              Aucun profil n'est encore enregistré sur la plateforme.
            </div>
          </div>
        ) : (
          <>
            <BookingProfileSearch
              filtersOptions={configData}
              onTargetingProfilesCtaClick={() =>
                setShowTargetingProfilesHelperDialog(true)
              }
            />

            <div className="lblock--l">
              {bookingProfiles.length > 0 ? (
                <>
                  <div className="--txt--right">
                    <select
                      value={sort}
                      className="select --inline --s"
                      onChange={(evt) => {
                        const field = evt.target.value;
                        setSearchParams((prev) => {
                          prev.set("sort", field);
                          return prev;
                        });
                      }}
                    >
                      {/* TODO: added mark in BookingProfile model */}
                      {/* <option value="mark:asc">Les mieux notés</option> */}
                      <option value="price:asc">Les moins chers</option>
                      <option value="price:desc">Les plus chers</option>
                      <option value="rating:desc">Les mieux notés</option>
                    </select>
                  </div>
                  <div className="cblock">
                    <PaginatedList
                      items={bookingProfiles}
                      render={(bookingProfile) => (
                        <BookingProfileCard
                          key={bookingProfile.id}
                          bookingProfile={bookingProfile}
                          bookmarked={!!bookmarksMap[bookingProfile.id]}
                          onBookmark={async () => {
                            const bookmark = bookmarksMap[bookingProfile.id];

                            if (bookmark) {
                              await deleteBookmark(bookmark.id);
                            } else {
                              await createBookmark(bookingProfile.id);
                            }

                            reload();
                          }}
                        />
                      )}
                      totalItems={totalProfilesNb}
                      pageSizes={[20, 40, 60]}
                      defaultPageSize={40}
                      className="booking-profiles-list"
                      scrollToAnchor={scrollAnchor}
                      extraElement={
                        <button
                          onClick={() =>
                            setShowTargetingProfilesHelperDialog(true)
                          }
                          className="booking-profile-list_item --fake-profile link --soft"
                        >
                          <div className="profile_row">
                            <ProfilePic
                              src={
                                import.meta.env.PROD
                                  ? bradPittProd
                                  : bradPittDev
                              }
                            />
                            <div className="profile_info-col">
                              <div>
                                <div className="lrow --distribute--h --center--v">
                                  <div className="profile_name">
                                    Le profil idéal
                                  </div>
                                </div>
                                <RatingDisplay profile={{ rating: 5 } as any} />
                              </div>

                              <div className="cta-text">
                                <Icon name="search" className="--l" />
                                <p>
                                  Options supplémentaires pour trouver plus de
                                  profils
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="profile_price-display">
                            <button className="btn--1 --btn--icon --chevron-right"></button>
                          </div>
                        </button>
                      }
                    />
                  </div>
                </>
              ) : (
                <div className="callout --light">
                  <Icon name="search" />
                  <div>
                    <strong>AUCUN RÉSULTAT</strong>
                    <br />
                    ne correspond actuellement à votre recherche.
                  </div>
                  <div className="container --wrap">
                    <div className="lblock card --s">
                      <div className="card_head --center">
                        <h2 className="card_title">
                          Autres possibilités pour trouver des profils
                        </h2>
                      </div>
                      <div className="card_body">
                        <NoProfileFoundCta
                          filtersOptions={configData}
                          user={loggedUser}
                          withTitle={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <NoProfileFoundBlock
                loggedUser={loggedUser}
                filtersOptions={configData}
              />
            </div>
          </>
        )}
      </div>
      {showTargetingProfilesHelperDialog && (
        <TargetingProfilesHelperDialog
          filtersOptions={configData}
          onClose={() => setShowTargetingProfilesHelperDialog(false)}
          loggedUser={loggedUser}
        />
      )}
    </div>
  );
};

export default ProfilesListPage;
