import { useNavigate } from "react-router-dom";
import Icon from "./Icon";
import { authService } from "../services/auth/authService";
import SxForm from "../forms/SxForm";
import SxField from "../forms/fields/SxField";
import SubmitButton from "./forms/SubmitButton";

const ProfileSearch = ({
  search,
  title,
  targetUrl,
}: {
  search?: string;
  title?: string;
  targetUrl?: string;
}) => {
  const navigate = useNavigate();
  const { isLoggedIn } = authService();

  return (
    <SxForm
      initialValues={{ search: search ?? "" }}
      onSubmit={(values) => {
        const baseUrl =
          targetUrl ?? (isLoggedIn() ? "/app" : "") + "/profiles-list";
        const searchQuery =
          values.search.length > 0 ? `?search=${values.search}` : "";

        return navigate(baseUrl + searchQuery);
      }}
    >
      <div className="input--search">
        {title && search && <h4 className="input--search_title">{title}</h4>}
        <SxField name="search" placeholder="Rechercher un profil" as="text" />
        <SubmitButton className="--addon btn--2">
          <Icon name="search" />
        </SubmitButton>
      </div>
    </SxForm>
  );
};

export default ProfileSearch;
