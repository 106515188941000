import { Link, useNavigate } from "react-router-dom";
import { ExternalContact } from "../../../../../../../services/externalDataIntegration/kwalaData/kwalaDataModel";
import Icon from "../../../../../../../components/Icon";
import { useState } from "react";
import { Campaign } from "../../../../../../../services/campaign/campaignModel";
import { ExternalDataIntegrationService } from "../../../../../../../services/externalDataIntegration/externalDataIntegrationService";

import { useConfirmationWithIntl } from "../../../../../../../components/ConfirmationDialog";
import Dialog from "../../../../../../../components/Dialog";
import useReload from "../../../../../../../hooks/useReload";

function ExternalContactsToEnrichList({
  externalContacts,
  campaignId,
}: {
  externalContacts: ExternalContact[];
  campaignId: Campaign["id"];
}) {
  const { confirm } = useConfirmationWithIntl(["campaign"]);
  const navigate = useNavigate();
  const reload = useReload();

  const externalData = ExternalDataIntegrationService();
  const [selectedContacts, setSelectedContacts] = useState<ExternalContact[]>(
    [],
  );
  const [showContactsList, setShowContactsList] = useState(false);

  const handleContactSelection = (
    contact: ExternalContact,
    isChecked: boolean,
  ) => {
    setSelectedContacts((prev) =>
      isChecked ? [...prev, contact] : prev.filter((c) => c.id !== contact.id),
    );
  };

  return (
    <div className="lblocks">
      <div className="lblocks">
        <h2 className="paragraph_title --txt--center">
          2 - Choisissez les contacts que vous souhaitez solliciter
        </h2>
        <div className="cblocks --xs">
          {externalContacts.map((contact) => (
            <label key={contact.id} className="checkbox --box">
              <input
                type="checkbox"
                checked={selectedContacts.some((c) => c.id === contact.id)}
                onChange={(e) =>
                  handleContactSelection(contact, e.target.checked)
                }
              />
              <div className="field-label">
                <strong>{contact.name}</strong>
                {!!contact.jobTitle && <> - {contact.jobTitle}</>}
                {!!contact.company && <> ({contact.company})</>}
              </div>
              {!!contact.linkedinUrl && (
                <Link
                  to={contact.linkedinUrl}
                  rel="noreferrer"
                  target="_blank"
                  className="link --icon"
                >
                  <Icon name="linkedin" className="--linkedin-blue" />
                </Link>
              )}
            </label>
          ))}
        </div>
      </div>
      {/* TODO : mutualize with other import method views */}
      {selectedContacts.length > 0 && (
        <div className="card --bg --drawer_sticky-footer">
          <div className="card_body">
            <div className="ui-row --center --txt--blue">
              <Icon name="users" className="--l" />
              <span className="title--70 --strong">
                {selectedContacts.length}
              </span>
            </div>
            <div className="--txt--center">
              {selectedContacts.length > 1
                ? "contacts vont être sollicités"
                : "contact va être sollicité"}{" "}
            </div>
            <div className="lblock ui-row --center--h">
              <button
                className="btn --outlined"
                onClick={() => setShowContactsList(true)}
              >
                Vérifier la liste
              </button>
              <button
                className="btn"
                onClick={() => {
                  return confirm(
                    "campaign:contacts-import.CONFIRM",
                    () =>
                      externalData
                        .createBookingRequestsFromTargettedContacts(
                          campaignId,
                          selectedContacts,
                        )
                        .then(() => {
                          navigate("../..");
                          reload();
                        }),
                    true,
                    {
                      title: "Confirmer l’envoi des sollicitations",
                      confirmText: "Confirmer et envoyer",
                    },
                  );
                }}
              >
                Solliciter {selectedContacts.length > 1 && "les"}{" "}
                {selectedContacts.length} contact
                {selectedContacts.length > 1 && "s"}{" "}
              </button>
            </div>
          </div>
        </div>
      )}
      {!!showContactsList && (
        <Dialog onClose={() => setShowContactsList(false)}>
          <div className="popup_body">
            <table className="table">
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Poste</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {selectedContacts.map((contact) => (
                  <tr key={contact.id}>
                    <td className="--shrink">
                      <strong>{contact.name}</strong>
                    </td>
                    <td>
                      <div className="ui-row --center--v">
                        <div>
                          {!!contact.jobTitle && <> - {contact.jobTitle}</>}
                          {!!contact.company && <> ({contact.company})</>}
                        </div>
                        {!!contact.linkedinUrl && (
                          <Link
                            to={contact.linkedinUrl}
                            rel="noreferrer"
                            target="_blank"
                            className="link --icon"
                          >
                            <Icon name="linkedin" className="--linkedin-blue" />
                          </Link>
                        )}
                      </div>
                    </td>
                    <td className="--shrink">
                      <button
                        className="btn--warning --btn--icon --delete"
                        onClick={() => handleContactSelection(contact, false)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Dialog>
      )}
    </div>
  );
}

export default ExternalContactsToEnrichList;
