import { useCallback, useMemo } from "react";
import SxForm from "../../../forms/SxForm";
import { MappedConfigData } from "../../../services/data/dataModel";
import { useNavigate, useSearchParams } from "react-router-dom";
import SxField from "../../../forms/fields/SxField";
import SxCheckbox from "../../../forms/fields/SxCheckbox";
import { User } from "../../../services/user/userModel";
import { array, boolean, number, object, string } from "yup";
import {
  transformEmptyToNull,
  transformEmptyToUndefined,
} from "../../../react-helpers/yup";
import SubmitButton from "../../../components/forms/SubmitButton";
import { bookingProfileService } from "../../../services/bookingProfile/bookingProfileService";
import ValidationErrors from "../../../forms/ValidationErrors";

const { createBookingProfilesAlert } = bookingProfileService();

const NoProfileFoundCta = ({
  filtersOptions,
  user,
  afterSubmit,
  withTitle = true,
}: {
  filtersOptions: MappedConfigData;
  user?: User;
  afterSubmit?: () => void;
  withTitle?: boolean;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const initialValues = useMemo(
    () => ({
      alert: false,
      agency: false,
      agencyNeed: null,
      search: searchParams.get("search") ?? "",
      companyActivities: searchParams.get("activities")?.split(",") ?? [],
      languages: searchParams.get("langs")?.split(",") ?? [],
      expertises: searchParams.get("exps")?.split(",") ?? [],
      skills: searchParams.get("skills")?.split(",") ?? [],
      hierarchyLevels: searchParams.get("levels")?.split(",") ?? [],
      companyHeadcounts: searchParams.get("headcounts")?.split(",") ?? [],
      minPrice: searchParams.get("minPrice")
        ? Number(searchParams.get("minPrice"))
        : null,
      maxPrice: searchParams.get("maxPrice")
        ? Number(searchParams.get("maxPrice"))
        : null,
      probono: searchParams.get("probono") === "true",
      email: user?.email ?? null,
      tel: user?.tel ?? null,
    }),
    [searchParams, user],
  );

  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
      const mappedParamsKeys = [
        "activities",
        "langs",
        "exps",
        "levels",
        "headcounts",
      ];
      const params: Record<string, string> = {};

      //Second, set the query params for the other fields
      Object.entries(values)
        .map(([key, value]) => {
          const paramKey = mappedParamsKeys.find((paramKey) => {
            return key.toLowerCase().includes(paramKey.slice(0, -1));
          });
          return paramKey ? [paramKey, value] : [key, value];
        })
        .forEach(([key, value]) => {
          if (key === "probono" && !value) return;
          if (value)
            params[key as string] = Array.isArray(value)
              ? value.join(",")
              : String(value);
        });

      return createBookingProfilesAlert(params).then(
        () => {
          navigate("./#top");
          afterSubmit?.();
        },
        () => {
          // On error do nothing
        },
      );
    },
    [afterSubmit, navigate],
  );

  return (
    <div className="--txt--left">
      {withTitle && (
        <div className="card_head">
          <h2 className="card_title">
            Autres possibilités pour trouver des profils
          </h2>
        </div>
      )}
      <SxForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={object().shape(
          {
            search: string()
              .label("Votre recherche")
              .transform(transformEmptyToUndefined),
            companyActivities: array(string())
              .label("Secteurs d'activité")
              .transform(transformEmptyToUndefined),
            languages: array(string())
              .label("Langues")
              .transform(transformEmptyToUndefined),
            expertises: array(string())
              .label("Domaines d'expertise")
              .transform(transformEmptyToUndefined),
            skills: array(string())
              .label("Compétences clés")
              .transform(transformEmptyToUndefined),
            hierarchyLevels: array(string())
              .label("Niveaux hiérarchiques")
              .transform(transformEmptyToUndefined),
            companyHeadcounts: array(string())
              .label("Effectifs")
              .transform(transformEmptyToUndefined),
            minPrice: number()
              .transform(transformEmptyToUndefined)
              .positive()
              .label("Prix min."),
            maxPrice: number()
              .transform(transformEmptyToUndefined)
              .positive()
              .label("Prix max."),
            probono: boolean().label("Profils 100% don"),
            alert: boolean().when("agency", {
              is: (checked: boolean) => !checked,
              then: () => boolean().oneOf([true]),
            }),
            agency: boolean().when("alert", {
              is: (checked: boolean) => !checked,
              then: () => boolean().oneOf([true]),
            }),
            agencyNeed: string()
              .label("Précisez votre besoin")
              .multiline()
              .nullable()
              .when("agency", {
                is: (checked: boolean) => !checked,
                then: (s) => s.notVisible().transform(() => null),
                otherwise: (s) => s.required(),
              }),
            email: string()
              .label("Adresse email")
              .email()
              .transform(transformEmptyToUndefined)
              .required(),
            tel: string()
              .label("Numéro de téléphone")
              .phoneNumber("Le numéro de téléphone est invalide")
              .transform(transformEmptyToNull)
              .nullable(),
          },
          [["agency", "alert"]],
        )}
        enableReinitialize
      >
        {({ values }) => (
          <>
            <div className="cblocks">
              <div className="flex-grid --stretch--v">
                <div className="card --bg">
                  <div className="card_body">
                    <SxCheckbox
                      name="alert"
                      className="checkbox --center--v"
                      label={
                        <div>
                          <div>
                            <strong>
                              Créez une alerte sur vos critères de recherche.
                            </strong>
                          </div>
                          <div>
                            Vous serez prévenu.e dès qu’un profil correspondant
                            apparaît sur la plateforme.
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
                <div className="card --bg lrow --center--v">
                  <div className="card_body">
                    <SxCheckbox
                      name="agency"
                      className="checkbox --center--v"
                      label={
                        <div>
                          <div>
                            <strong>
                              Faites appel à nous pour trouver les meilleurs
                              profils.
                            </strong>
                          </div>
                          <div>Nous vous proposons 3 profils sous 24H.</div>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="cblock">
              <SxField name="agencyNeed" />
            </div>

            <div className="card cblock">
              <div className="card_body">
                <div className="cblock">
                  <SxField name="search" />
                </div>
                <div className="cblock grid--2">
                  <SxField
                    as="autocomplete"
                    name="companyActivities"
                    placeholder={"- Sélectionnez des secteurs -"}
                    options={filtersOptions.companyActivities}
                    isMulti
                  />
                  <SxField
                    as="autocomplete"
                    name="languages"
                    placeholder={"- Sélectionnez des langues -"}
                    options={filtersOptions.languages}
                    isMulti
                  />
                  <SxField
                    as="autocomplete"
                    name="expertises"
                    options={filtersOptions.expertises}
                    placeholder={"- Sélectionnez des domaines -"}
                    isMulti
                  />
                  <SxField
                    as="autocomplete"
                    name="skills"
                    placeholder={"- Sélectionnez des compétences -"}
                    options={filtersOptions.skills}
                    isMulti
                  />
                  <SxField
                    as="autocomplete"
                    name="hierarchyLevels"
                    placeholder={"- Sélectionnez des niveaux -"}
                    options={filtersOptions.hierarchyLevels}
                    isMulti
                  />
                  <SxField
                    as="autocomplete"
                    name="companyHeadcounts"
                    placeholder={"- Sélectionnez des tranches -"}
                    options={filtersOptions.companyHeadcounts}
                    isMulti
                  />
                  <div className="grid--2 form-block">
                    <SxField name="minPrice" />
                    <SxField name="maxPrice" />
                  </div>
                  <div className="card">
                    <div className="card_body">
                      <SxCheckbox name="probono" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card cblock">
              <div className="card_body flex-grid">
                <div>
                  <SxField name="email" />
                </div>
                <div>
                  <SxField name="tel" />
                  <div className="field-tip">
                    Ce champ est optionnel, mais peut faciliter nos échanges et
                    nous aider à affiner la recherche.
                  </div>
                </div>
              </div>
            </div>

            <div className="form_footer">
              <ValidationErrors />
              <SubmitButton className="btn cblock">
                {values.agency ? "Envoyer" : "Enregistrer"}
              </SubmitButton>
            </div>
          </>
        )}
      </SxForm>
    </div>
  );
};

export default NoProfileFoundCta;
