import SubmitButton from "../../../components/forms/SubmitButton";
import { Value } from "../../../forms/fields/SxAutocomplete";
import SxField from "../../../forms/fields/SxField";
import { MappedConfigData } from "../../../services/data/dataModel";

const ProbonoAdvancedSearch = ({
  filtersOptions,
}: {
  filtersOptions: MappedConfigData & { functions: Value[] };
}) => {
  return (
    <div className="card --bg --light --overflow slide-left">
      <div className="card_body">
        <div className="grid--3">
          <SxField
            as="autocomplete"
            name="companyActivities"
            placeholder={"- Sélectionnez des secteurs -"}
            options={filtersOptions.companyActivities}
            isMulti
          />
          <SxField
            as="autocomplete"
            name="functions"
            placeholder={"- Sélectionnez des fonctions -"}
            options={filtersOptions.functions}
            isMulti
          />
          <SxField
            as="autocomplete"
            name="companyHeadcounts"
            placeholder={"- Sélectionnez des tranches -"}
            options={filtersOptions.companyHeadcounts}
            isMulti
          />
        </div>
        <div className="lblock --txt-center">
          <SubmitButton type="submit" className="btn">
            Rechercher
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default ProbonoAdvancedSearch;
