import Icon from "../../../../components/Icon";
import { BookingProfile } from "../../../../services/bookingProfile/bookingProfileModel";

const RatingDisplay = ({
  profile,
  ratingAmount,
  commentsAmount,
  onRatingsClick,
}: {
  profile: BookingProfile;
  ratingAmount?: number | null;
  commentsAmount?: number | null;
  onRatingsClick?(): void;
}) => {
  return (
    profile.rating !== null &&
    profile.rating !== undefined && (
      <div className="rating-display">
        <Icon name="star" />{" "}
        <span>
          <strong>{Math.round(profile.rating * 10) / 10}</strong>
          /5
        </span>
        {(ratingAmount ?? commentsAmount ?? null) && (
          <div>
            (
            <button className="link --s" onClick={onRatingsClick}>
              {[
                !!ratingAmount && ratingAmount > 0 && `${ratingAmount} notes`,
                !!commentsAmount &&
                  commentsAmount > 0 &&
                  `${commentsAmount} avis`,
              ]
                .filter((t) => t)
                .join(", ")}
            </button>
            )
          </div>
        )}
      </div>
    )
  );
};

export default RatingDisplay;
