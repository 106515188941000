import { Link } from "react-router-dom";

function HowItWorksPage() {
  return (
    <div className="container --wrap">
      <div className="page_head">
        <h1 className="page_title">Comment ça marche ?</h1>
      </div>
      <div className="page-content lblocks">
        <div className="grid">
          <div className="card --l lcol --stretch--h --distribute--v">
            <div className="card_body lblocks">
              <h3 className="section_title --txt--center">Je veux échanger</h3>
              <div className="cblocks">
                <p>
                  1. J’exprime mon besoin : l’objet de ma demande et ce que
                  j’attends de l’échange
                </p>
                <p>2. Je sélectionne les profils que je souhaite solliciter</p>
                <p>
                  3. Je réalise les visios avec les profils ayant accepté ma
                  demande
                </p>
                <p>4. Je les rémunère pour le temps consacré</p>
              </div>
            </div>
            <div className="card_footer">
              <Link to="/profiles-list" className="btn--2 --block">
                Contacter quelqu'un
              </Link>
            </div>
          </div>
          <div className="card --l lcol --stretch--h --distribute--v">
            <div className="card_body lblocks">
              <h3 className="section_title --txt--center">
                Je partage mes connaissances
              </h3>
              <div className="cblocks">
                <p>1. Je renseigne mes compétences et mes disponibilités</p>
                <p>2. Je sélectionne les sollicitations que je reçois</p>
                <p>
                  3. Je réalise les visios avec les personnes m’ayant sollicité
                </p>
                <p>
                  4. Je suis rémunéré ou j’en fais bénéficier l’association de
                  mon choix
                </p>
              </div>
            </div>
            <div className="card_footer">
              <Link to="/login" className="btn --block">
                Créer mon profil Tisio
              </Link>
            </div>
          </div>
        </div>
        <div className="card --bg">
          <div className="card_body">
            <div className="lblock--l cblocks">
              <div className="cblocks --s">
                <h2 className="section_title">Tisio c'est quoi ?</h2>
                <p>
                  Tisio est une plateforme de mise en relation entre personnes
                  souhaitant vendre ou acheter un temps d’échange en visio pour
                  du conseil, un témoignage, un avis ou un partage d’expérience
                  et/ou d’expertise.
                </p>
              </div>
              <div className="cblocks --s">
                <h2 className="section_title">A quoi peut me servir Tisio ?</h2>
                <p>
                  A plein de choses ! Par exemple… recueillir des conseils
                  pratiques sur un sujet, contacter des prospects, échanger avec
                  un expert, envisager une reconversion, solliciter un avis
                  avant de lancer un projet, et tout ce que vous pourrez
                  imaginer d’autre !
                </p>
              </div>
              <div className="cblocks --s">
                <h2 className="section_title">
                  Qui est-ce que je peux solliciter ?
                </h2>
                <p>
                  Tout le monde ! A partir du moment où ils ont une adresse mail
                  pro ou un profil LinkedIn.
                </p>
                <p>Vous avez la possibilité de :</p>
                <ul>
                  <li>sélectionner des profils dans notre base</li>
                  <li>
                    nous transmettre vos critères de ciblage : nous irons les
                    chercher pour vous !
                  </li>
                  <li>
                    importer vous-même une liste de contacts (nécessite un plan
                    pro)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="--txt--center">
          <a className="btn--2" href="https://info.tisio.fr/faq">
            Consulter la FAQ
          </a>
        </div>
      </div>
    </div>
  );
}

export default HowItWorksPage;
