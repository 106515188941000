import {
  ExternalContact,
  KwalaInitiateSearchData,
  KwalaResponse,
} from "./kwalaData/kwalaDataModel";
import { Campaign } from "../campaign/campaignModel";
import {
  kwalaEnrichContactsRequest,
  kwalaInitiateSearchRequest,
} from "./externalDataIntegrationApi";
import { toastsWithIntl } from "../toastService";

interface ExternalDataIntegrationService {
  getTargettedContacts: (
    campaignId: Campaign["id"],
    data: KwalaInitiateSearchData,
  ) => Promise<ExternalContact[]>;
  createBookingRequestsFromTargettedContacts: (
    campaignId: Campaign["id"],
    contactsToEnrich: ExternalContact[],
  ) => Promise<void>;
}

export function ExternalDataIntegrationService(): ExternalDataIntegrationService {
  const { toastError, toastSuccess } = toastsWithIntl(["global", "campaign"]);

  const getTargettedContacts: ExternalDataIntegrationService["getTargettedContacts"] =
    (campaignId, data) => {
      return kwalaInitiateSearchRequest(campaignId, data)
        .then((extApiResponse: KwalaResponse) => {
          // Kwala mapping
          return Promise.resolve(
            extApiResponse.task_result.results.map((r) => {
              return {
                id: r.id,
                name: r.full_name,
                company: r.company,
                jobTitle: r.current_title,
                linkedinUrl: r.linkedin_url,
                linkedinId: r.linkedin_id,
              };
            }),
          );
        })
        .catch((err) => {
          toastError("global:GENERIC_ERROR", {
            id: "generic-error",
          });
          return Promise.reject(err);
        });
    };

  const createBookingRequestsFromTargettedContacts: ExternalDataIntegrationService["createBookingRequestsFromTargettedContacts"] =
    (campaignId, contactsToEnrich) => {
      return kwalaEnrichContactsRequest(campaignId, contactsToEnrich)
        .then(() => {
          toastSuccess("campaign:contacts-import.CONTACTS_ENRICHING_PENDING", {
            id: "contacts-enriching-pending",
          });
        })
        .catch((err) => {
          toastError("global:GENERIC_ERROR", {
            id: "generic-error",
          });
          return Promise.reject(err);
        });
    };

  return {
    getTargettedContacts,
    createBookingRequestsFromTargettedContacts,
  };
}
