import { object, string, array } from "yup";
import SxForm from "../../../forms/SxForm";
import SxField from "../../../forms/fields/SxField";
import { transformEmptyToUndefined } from "../../../react-helpers/yup";
import { MappedConfigData } from "../../../services/data/dataModel";
import { useSearchParams } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import SubmitButton from "../../../components/forms/SubmitButton";
import FormResetButton from "../../../components/forms/FormResetButton";
import Icon from "../../../components/Icon";
import { cx } from "../../../react-helpers/css";
import ProbonoAdvancedSearch from "./ProbonoAdvancedSearch";
import { Value } from "../../../forms/fields/SxAutocomplete";

interface InitialValues {
  search: string;
  companyActivities: string[];
  companyHeadcounts: string[];
  functions: string[];
}

const ProbonoSearch = ({
  filtersOptions,
}: {
  filtersOptions: MappedConfigData & { functions: Value[] };
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const initialValues: InitialValues = useMemo(
    () => ({
      search: searchParams.get("search") ?? "",
      companyHeadcounts: searchParams.get("headcounts")?.split(",") ?? [],
      companyActivities: searchParams.get("activities")?.split(",") ?? [],
      functions: searchParams.get("functions")?.split(",") ?? [],
    }),
    [searchParams],
  );

  const handleSubmit = useCallback(
    (values: InitialValues) => {
      const mappedParamsKeys = ["activities", "headcounts"];
      setSearchParams((prev) => {
        //First, drop all the query params (except limit and sort)
        Array.from(prev.keys()).forEach((key) => {
          if (key !== "limit" && key !== "sort") prev.delete(key);
        });

        //Second, set the query params for the other fields
        Object.entries(values)
          .map(([key, value]) => {
            const paramKey = mappedParamsKeys.find((paramKey) => {
              return key.toLowerCase().includes(paramKey.slice(0, -1));
            });
            return paramKey ? [paramKey, value] : [key, value];
          })
          .forEach(([key, value]) => {
            prev.set(key, Array.isArray(value) ? value.join(",") : value);
          });

        // If ref field is defined, we remove it from the query params
        if (prev.has("ref")) prev.delete("ref");

        return prev;
      });

      return Promise.resolve();
    },
    [setSearchParams],
  );
  return (
    <SxForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={object({
        search: string().transform(transformEmptyToUndefined).clearable(),
        companyActivities: array(string())
          .label("Secteurs d'activité")
          .transform(transformEmptyToUndefined),
        companyHeadcounts: array(string())
          .label("Effectifs")
          .transform(transformEmptyToUndefined),
        functions: array(string())
          .label("Fonction")
          .transform(transformEmptyToUndefined),
      })}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ submitForm }) => (
        <div className="lblocks">
          <div className="input--search">
            <SxField
              name="search"
              placeholder={"Rechercher des profils"}
              onReset={() => {
                void submitForm();
              }}
            />
            <SubmitButton type="submit" className="--addon btn--1">
              <Icon name="search" />
            </SubmitButton>
          </div>
          <div className="lrow --distribute--h --center--v --gap--v--xs">
            <button
              type="button"
              className={cx(["btn--collapse", showAdvancedSearch && "--open"])}
              onClick={() => setShowAdvancedSearch((prev) => !prev)}
            >
              Recherche avancée
            </button>

            <FormResetButton
              className="link--error --with-icon --s"
              onClick={(_, { resetForm }) => {
                // we must to reset search params before reseting the form because of the initialValues depending on the search params
                setSearchParams((prev) => {
                  for (const key of Array.from(prev.keys())) {
                    if (key !== "limit" && key !== "sort" && key !== "search")
                      prev.delete(key);
                  }
                  return prev;
                });
                resetForm();
              }}
              ignoredFields={["search"]}
            >
              <Icon name="reset" />
              Réinitialiser les filtres
            </FormResetButton>
          </div>
          {showAdvancedSearch && (
            <ProbonoAdvancedSearch filtersOptions={filtersOptions} />
          )}
        </div>
      )}
    </SxForm>
  );
};
export default ProbonoSearch;
