import { useEffect, useMemo, useState } from "react";
import { useConfirmationWithIntl } from "../../../../../../components/ConfirmationDialog";
import SubmitButton from "../../../../../../components/forms/SubmitButton";
import { CsvHeadersMapping } from "./CsvImportStep";
import { useTranslation } from "react-i18next";
import { ImportedUser } from "./ContactsImportDialog";
import { randomUUID } from "../../../../../../react-helpers/crypto";
import { cx } from "../../../../../../react-helpers/css";
import { object, string, AnySchema, ValidationError } from "yup";
import Icon from "../../../../../../components/Icon";
import { Campaign } from "../../../../../../services/campaign/campaignModel";
import Dialog from "../../../../../../components/Dialog";

interface Props<S> {
  campaign: Campaign;
  headersMapping: CsvHeadersMapping;
  records: Record<string, string>[];
  setContacts: (prev: (prev: ImportedUser[]) => ImportedUser[]) => void;
  validContacts: ImportedUser[];
  addValidContacts: () => Promise<void>;
  schema: S;
}

const csvSchema = object({
  firstname: string().required("La colonne prénom est requise."),
  lastname: string().required("La colonne nom est requise."),
  email: string().required("La colonne email est requise."),
});

const CsvImportValidation = <S extends AnySchema>({
  campaign,
  headersMapping,
  records,
  setContacts,
  validContacts,
  addValidContacts,
  schema,
}: Props<S>) => {
  const MAX_CONTACTS_IMPORT = 300;

  const { t } = useTranslation(["campaign"]);
  const { confirm } = useConfirmationWithIntl(["campaign"]);

  const [importWarnings, setImportWarnings] = useState<string[]>([]);
  const [showValidContacts, setShowValidContacts] = useState(false);

  const headersMappingErrors = useMemo(() => {
    let errors: string[] = [];
    try {
      csvSchema.validateSync(headersMapping, {
        abortEarly: false,
      });
    } catch (e) {
      errors = (e as ValidationError).inner.map((err) => err.message);
    }
    return errors;
  }, [headersMapping]);

  useEffect(() => {
    if (headersMappingErrors.length === 0) {
      const warnings = [];

      const contacts = records.map((record) => ({
        firstname: record[headersMapping.firstname!],
        lastname: record[headersMapping.lastname!],
        email: record[headersMapping.email!],
      }));
      const validatedContacts = contacts.filter((c) => schema.isValidSync(c));

      if (contacts.length !== validatedContacts.length) {
        warnings.push(
          t("campaign:csv-import.WARNING", {
            count: validatedContacts.length,
            maxCount: contacts.length,
          }),
        );
      }

      if (validatedContacts.length > MAX_CONTACTS_IMPORT) {
        warnings.push(
          t("campaign:csv-import.TOO_MUCH", {
            count: MAX_CONTACTS_IMPORT,
            maxCount: contacts.length,
          }),
        );
      }

      setImportWarnings(warnings);
      setContacts(
        () =>
          validatedContacts
            .slice(0, MAX_CONTACTS_IMPORT)
            .map((u) => ({ ...u, id: randomUUID() })) as ImportedUser[],
      );
    } else {
      setContacts(() => []);
      setImportWarnings([]);
    }
  }, [
    headersMapping.email,
    headersMapping.firstname,
    headersMapping.lastname,
    headersMappingErrors.length,
    setContacts,
    schema,
    records,
    t,
  ]);

  const removeContact = (contactToRemove: ImportedUser) => {
    setContacts((prevContacts) =>
      prevContacts.filter((contact) => contact.id !== contactToRemove.id),
    );
  };

  return (
    <>
      {Object.keys(headersMapping).length > 0 &&
        Object.keys(headersMappingErrors).length > 0 && (
          <div>
            {headersMappingErrors.map((err) => (
              <div className="field-error" key={err}>
                {err}
              </div>
            ))}
          </div>
        )}
      <div
        className={cx([
          "card",
          validContacts.length > 0 ? "--bg" : "--no-border",
        ])}
      >
        <div className="card_body cblocks">
          {validContacts.length > 0 && (
            <div>
              <div className="ui-row --center --txt--blue">
                <Icon name="users" className="--l" />
                <span className="title--70 --strong">
                  {validContacts.length}
                </span>
              </div>
              <div>
                {validContacts.length > 1
                  ? "contacts vont être importés"
                  : "contact va être importé"}{" "}
              </div>
            </div>
          )}

          {importWarnings.map((warning) => (
            <div className="info --warning" key={warning}>
              {warning}
            </div>
          ))}

          <div className="ui-row --center--h">
            <button
              type="button"
              className="btn --outlined"
              onClick={() => setShowValidContacts(true)}
            >
              vérifier la liste
            </button>
            {campaign.type === "TARGETED" ? (
              <SubmitButton
                type="button"
                className="btn"
                onClick={addValidContacts}
                disabled={validContacts.length === 0}
              >
                Importer {validContacts.length > 1 && "les"}{" "}
                {validContacts.length} contact
                {validContacts.length > 1 && "s"}
              </SubmitButton>
            ) : (
              <SubmitButton
                type="button"
                className="btn"
                disabled={validContacts.length === 0}
                onClick={() => {
                  return confirm(
                    "campaign:contacts-import.CONFIRM",
                    addValidContacts,
                    true,
                    {
                      title: "Confirmer l’envoi des sollicitations",
                      confirmText: "Confirmer et envoyer",
                    },
                  );
                }}
              >
                Solliciter {validContacts.length > 1 && "les"}{" "}
                {validContacts.length} contact
                {validContacts.length > 1 && "s"}
              </SubmitButton>
            )}
          </div>
        </div>
        {showValidContacts && (
          <Dialog onClose={() => setShowValidContacts(false)}>
            <div className="popup_body">
              <table className="table">
                <thead>
                  <tr>
                    <th>{t("campaign:map-csv-header.FIRSTNAME")}</th>
                    <th>{t("campaign:map-csv-header.LASTNAME")}</th>
                    <th>{t("campaign:map-csv-header.EMAIL")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {validContacts.map((contact) => (
                    <tr key={contact.id}>
                      <td>{contact.firstname}</td>
                      <td>{contact.lastname}</td>
                      <td>{contact.email}</td>

                      <td className="--shrink">
                        <button
                          className="btn--warning --btn--icon --delete"
                          onClick={() => removeContact(contact)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default CsvImportValidation;
