import { useLoaderData, useSearchParams } from "react-router-dom";
import { BookingProfile } from "../../../services/bookingProfile/bookingProfileModel";
import { Bookmark } from "../../../services/bookmark/bookmarkModel";
import { MappedConfigData } from "../../../services/data/dataModel";
import { User } from "../../../services/user/userModel";
import useReload from "../../../hooks/useReload";
import { createRef, useMemo } from "react";
import { bookmarkService } from "../../../services/bookmark/bookmarkService";
import PaginatedList from "../../../components/PaginatedList";
import BookingProfileCard from "../../../components/profiles/BookingProfileCard";
import Icon from "../../../components/Icon";
import ProbonoSearch from "./ProbonoSearch";
import { Value } from "../../../forms/fields/SxAutocomplete";
import BackButton from "../../../components/BackButton";

const { createBookmark, deleteBookmark } = bookmarkService();

const FUNCTIONS = [
  "Direction générale",
  "Direction commerciale",
  "Direction marketing",
  "Direction communication",
  "Direction des systèmes d’information / IT",
  "Direction des ressources humaines",
  "Direction administrative et financière",
  "Direction juridique",
  "Direction R&D",
  "Direction RSE",
  "Direction de la relation client",
  "Direction des achats",
  "Direction industrielle",
  "Direction des opérations",
];

const ProbonoListPage = () => {
  const { bookingProfiles, totalProfilesNb, configData, bookmarks } =
    useLoaderData() as {
      bookingProfiles: BookingProfile[];
      bookmarks: Bookmark[];
      configData: MappedConfigData;
      totalProfilesNb: number;
      loggedUser?: User;
    };

  const reload = useReload();

  const [searchParams, setSearchParams] = useSearchParams();
  const scrollAnchor = createRef<HTMLDivElement>();

  const sort = useMemo(() => {
    return searchParams.get("sort") ?? "rating:desc";
  }, [searchParams]);

  const bookmarksMap = useMemo(
    () =>
      Object.fromEntries(
        bookmarks.map((bookmark) => [bookmark.bookingProfileId, bookmark]),
      ),
    [bookmarks],
  );

  return (
    <div className="container">
      <div ref={scrollAnchor} />
      <div className="page_head">
        <div>
          <BackButton fallbackUrl="/app/dashboard" />
          <h1 className="page_title">Profils Tisio pro bono</h1>
        </div>
      </div>

      <div className="page-content">
        {bookingProfiles.length === 0 &&
        Array.of(searchParams.entries()).length === 0 ? (
          <div className="info">
            <div className="info_title">
              Aucun profil n'est encore enregistré sur la plateforme.
            </div>
          </div>
        ) : (
          <>
            <ProbonoSearch
              filtersOptions={{
                ...configData,
                functions: FUNCTIONS.map(
                  (f) =>
                    ({
                      label: f,
                      value: f,
                    }) as Value,
                ),
              }}
            />

            <div className="lblock--l">
              {bookingProfiles.length > 0 ? (
                <>
                  <div className="--txt--right">
                    <select
                      value={sort}
                      className="select --inline --s"
                      onChange={(evt) => {
                        const field = evt.target.value;
                        setSearchParams((prev) => {
                          prev.set("sort", field);
                          return prev;
                        });
                      }}
                    >
                      {/* TODO: added mark in BookingProfile model */}
                      {/* <option value="mark:asc">Les mieux notés</option> */}
                      <option value="price:asc">Les moins chers</option>
                      <option value="price:desc">Les plus chers</option>
                      <option value="rating:desc">Les mieux notés</option>
                    </select>
                  </div>
                  <div className="cblock">
                    <PaginatedList
                      items={bookingProfiles}
                      render={(bookingProfile) => (
                        <BookingProfileCard
                          key={bookingProfile.id}
                          bookingProfile={bookingProfile}
                          bookmarked={!!bookmarksMap[bookingProfile.id]}
                          onBookmark={async () => {
                            const bookmark = bookmarksMap[bookingProfile.id];

                            if (bookmark) {
                              await deleteBookmark(bookmark.id);
                            } else {
                              await createBookmark(bookingProfile.id);
                            }

                            reload();
                          }}
                        />
                      )}
                      totalItems={totalProfilesNb}
                      pageSizes={[20, 40, 60]}
                      defaultPageSize={40}
                      className="booking-profiles-list"
                      scrollToAnchor={scrollAnchor}
                    />
                  </div>
                </>
              ) : (
                <div className="callout --light">
                  <Icon name="search" />
                  <div>
                    <strong>AUCUN RÉSULTAT</strong>
                    <br />
                    ne correspond à votre recherche.
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProbonoListPage;
