import { useState } from "react";
import TargetingProfilesHelperDialog from "./TargetingProfilesHelperDialog";
import { User } from "../../../services/user/userModel";
import { cx } from "../../../react-helpers/css";
import Icon from "../../../components/Icon";
import { MappedConfigData } from "../../../services/data/dataModel";

const NoProfileFoundBlock = ({
  loggedUser,
  filtersOptions,
}: {
  loggedUser?: User;
  filtersOptions: MappedConfigData;
}) => {
  const [
    showTargetingProfilesHelperDialog,
    setShowTargetingProfilesHelperDialog,
  ] = useState(false);
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className={cx(["floating-help", open && "--open"])}>
        <button className="floating_icon" onClick={() => setOpen(true)}>
          <Icon name="help_search" />
        </button>

        <div className="floating_content">
          <div className="--txt--right">
            <button
              className="icon--close"
              onClick={(evt) => {
                evt.stopPropagation();
                setOpen(false);
              }}
            />
          </div>

          <div className="--bold">
            Vous ne trouvez pas le profil que vous cherchez ?
          </div>
          <div>
            Voici{" "}
            <button
              className="link --s"
              onClick={() => setShowTargetingProfilesHelperDialog(true)}
            >
              d'autres possibilités
            </button>{" "}
            pour trouver des profils
          </div>
        </div>
      </div>

      {showTargetingProfilesHelperDialog && (
        <TargetingProfilesHelperDialog
          filtersOptions={filtersOptions}
          onClose={() => setShowTargetingProfilesHelperDialog(false)}
          loggedUser={loggedUser}
        />
      )}
    </>
  );
};

export default NoProfileFoundBlock;
