import { array, boolean, number, object, string } from "yup";
import { authService } from "../auth/authService";
import { toastsWithIntl } from "../toastService";
import {
  getBookingProfileByRequestUuidRequest,
  getBookingProfileByUsernameRequest,
  getBookingProfileRequest,
  createBookingProfilesAlertRequest,
  toggleBookingProfilePublishedRequest,
  updateBookingProfileRequest,
} from "./bookingProfileApi";
import { BookingProfile } from "./bookingProfileModel";
import {
  checkLinkedinUrl,
  transformEmptyToNull,
  transformEmptyToUndefined,
} from "../../react-helpers/yup";
import { BookingRequest } from "../bookingRequest/bookingRequestModel";
import { MAXIMUM_VISIO_PRICE, MINIMUM_VISIO_PRICE } from "../config";

interface BookingProfileService {
  getCurrentUserBookingProfile(communitySlug: string): Promise<BookingProfile>;
  getBookingProfileBySlugAndUsername(
    communitySlug: string,
    username: string,
  ): Promise<BookingProfile>;
  getBookingProfileByRequestUuid(
    bookingRequestUuid: BookingRequest["uuid"],
  ): Promise<BookingProfile>;
  updateBookingProfile(bookingProfile: BookingProfile): Promise<void>;
  toggleBookingProfile(
    bookingProfileId: BookingProfile["id"],
    published?: boolean,
  ): Promise<void>;
  createBookingProfilesAlert(params: Record<string, string>): Promise<void>;
}

export function bookingProfileService(): BookingProfileService {
  const { toastError, toastSuccess } = toastsWithIntl([
    "global",
    "bookingProfile",
  ]);
  const { getLoggedUserId } = authService();

  const getCurrentUserBookingProfile: BookingProfileService["getCurrentUserBookingProfile"] =
    (communitySlug) => {
      return getBookingProfileRequest(communitySlug, getLoggedUserId()!).then(
        (bookingProfile) => {
          bookingProfile.isComplete =
            bookingProfilePositionSchema.isValidSync(bookingProfile) &&
            bookingProfileDescriptionSchema.isValidSync(bookingProfile) &&
            bookingProfilePreferencesSchema.isValidSync({
              ...bookingProfile,
              price: bookingProfile.price / 100,
            });

          return bookingProfile;
        },
        (err) => {
          toastError("global:GENERIC_ERROR", {
            id: "generic-error",
          });
          return Promise.reject(err);
        },
      );
    };

  const getBookingProfileBySlugAndUsername: BookingProfileService["getBookingProfileBySlugAndUsername"] =
    (communitySlug, username) => {
      return getBookingProfileByUsernameRequest(username, communitySlug).catch(
        (err) => {
          toastError("global:GENERIC_ERROR", {
            id: "generic-error",
          });
          return Promise.reject(err);
        },
      );
    };

  const updateBookingProfile: BookingProfileService["updateBookingProfile"] = (
    bookingProfile,
  ) => {
    return updateBookingProfileRequest(bookingProfile).then(
      () => toastSuccess("bookingProfile:update.SUCCESS"),
      () => toastError("bookingProfile:update.ERROR"),
    );
  };

  const toggleBookingProfile: BookingProfileService["toggleBookingProfile"] = (
    bookingProfileId,
    published,
  ) => {
    return toggleBookingProfilePublishedRequest(
      bookingProfileId,
      published,
    ).then(
      (bookingProfile) =>
        toastSuccess(
          bookingProfile.published
            ? "bookingProfile:toggle-published.SUCCESS_ACTIVATED"
            : "bookingProfile:toggle-published.SUCCESS_DEACTIVATED",
        ),
      () =>
        toastError("global:GENERIC_ERROR", {
          id: "generic-error",
        }),
    );
  };

  const getBookingProfileByRequestUuid: BookingProfileService["getBookingProfileByRequestUuid"] =
    (bookingRequestUuid) => {
      return getBookingProfileByRequestUuidRequest(bookingRequestUuid).catch(
        (err) => {
          toastError("global:GENERIC_ERROR", {
            id: "generic-error",
          });
          return Promise.reject(err);
        },
      );
    };

  const createBookingProfilesAlert: BookingProfileService["createBookingProfilesAlert"] =
    (params) => {
      return createBookingProfilesAlertRequest(params).then(
        () => {
          toastSuccess("bookingProfile:create-alert.SUCCESS");
        },
        () => {
          toastError("global:GENERIC_ERROR", {
            id: "generic-error",
          });
        },
      );
    };

  return {
    getCurrentUserBookingProfile,
    getBookingProfileByRequestUuid,
    getBookingProfileBySlugAndUsername,
    updateBookingProfile,
    toggleBookingProfile,
    createBookingProfilesAlert,
  };
}

export const bookingProfileDescriptionSchema = object({
  description: string()
    .label("Présentez-vous")
    .transform(transformEmptyToUndefined)
    .required(),
  linkedin: string()
    .transform(transformEmptyToNull)
    .label("URL de votre profil linkedin")
    .trim()
    .test({
      test: checkLinkedinUrl,
      message: 'URL incorrecte. Ex: "https://www.linkedin.com/in/username"',
    })
    .nullable(),
  expertises: array()
    .label("Domaines d'expertise")
    .transform(transformEmptyToUndefined)
    .max(3, "Vous ne pouvez pas sélectionner plus de 3 domaines")
    .required(),
  skills: array()
    .label("Compétences clés")
    .transform(transformEmptyToUndefined)
    .max(10, "Vous ne pouvez pas sélectionner plus de 10 compétences")
    .required(),
  languages: array()
    .label("Langues dans lesquelles je peux proposer un échange")
    .transform(transformEmptyToUndefined)
    .required(),
});

export const bookingProfilePositionSchema = object({
  position: string()
    .label("Intitulé du poste")
    .max(100, "max. 100 caractères")
    .required(),
  companyHeadcountId: string()
    .label("Effectif de l'entreprise")
    .transform(transformEmptyToUndefined)
    .required(),
  companyActivityId: string()
    .label("Secteur d'activité de l'entreprise")
    .transform(transformEmptyToUndefined)
    .required(),
  hierarchyLevelId: string()
    .label("Niveau hierarchique")
    .transform(transformEmptyToUndefined)
    .required(),
  companyName: string()
    .label("Nom de l'entreprise")
    .transform(transformEmptyToUndefined)
    .required(),
});

export const bookingProfilePreferencesSchema = object({
  price: number()
    .label("Votre tarif pour une visio de 30 minutes (€)")
    .transform(transformEmptyToUndefined)
    .required()
    .min(
      MINIMUM_VISIO_PRICE,
      `Le montant doit être supérieur ou égal à ${MINIMUM_VISIO_PRICE}€`,
    )
    .max(
      MAXIMUM_VISIO_PRICE,
      `Le montant doit être inférieur ou égal à ${MAXIMUM_VISIO_PRICE}€`,
    ),
  associationId: string().label("Votre association").required(),
  probono: boolean()
    .label("100% don")
    .transform((v) => !!v),
  private: boolean()
    .label("Profil privé")
    .transform((v) => !!v),
});
